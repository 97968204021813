const preloader = document.querySelector('.preloader');

//PIKULIN MODAL
document.onkeydown = (evt) => {
    evt = evt || window.event;
    let isEscape = false;
    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
        isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
        pikulinModalClose();
    }
};
initPikulinModalMove();
function initPikulinModalMove() {
    document.querySelectorAll('.pikulin-modal .pikulin-modal.move').forEach((modal)=>{
        modal.classList.remove('move');
        document.body.appendChild(modal.cloneNode(true));
        modal.remove();
    });
    document.querySelectorAll('.pikulin-modal.move').forEach((modal)=>{
        modal.classList.remove('move');
        document.body.appendChild(modal.cloneNode(true));
        modal.remove();
    });
}
initPikulinModalBtns();
function initPikulinModalBtns() {
    document.querySelectorAll('.btn-pikulin-modal:not(.pikulin-modal-inited)').forEach((btn) => {
        btn.classList.add('pikulin-modal-inited');
        btn.addEventListener('click',() => {
            if(btn.hasAttribute('data-change')) {
                const change = JSON.parse(btn.getAttribute('data-change'));
                if(undefined !== change && change.length>0) {
                    change.forEach(data=>{
                        const elements= document.querySelectorAll(data.toggle);
                        if(elements.length>0) {
                            let content = null;
                            if (undefined !== data.contentToggle) {
                                const get_element = document.querySelector(data.contentToggle);
                                if (get_element !== null) {
                                    if(get_element.tagName==='INPUT') {
                                        content = (get_element.hasAttribute('data-url') ? get_element.getAttribute('data-url') : '')+get_element.value;
                                    } else {
                                        content = undefined === data.getAttribute ? get_element.innerHTML : get_element.getAttribute(data.getAttribute);
                                    }
                                }
                            } else if (undefined !== data.content) {
                                content = data.content;
                            }
                            if (undefined === data.setAttribute) {
                                elements.forEach(element => {
                                    element.innerHTML = content;
                                });
                            } else {
                                if(data.setAttribute==="selected") {
                                    let t = elements[0].parentElement.querySelector('option[selected]');
                                    if(t!==null) {
                                        t.removeAttribute('selected');
                                    }
                                }
                                elements.forEach(element => {
                                    element.setAttribute(data.setAttribute, content);
                                });
                            }
                        }
                    });
                }
            }
            pikulinModalShow(document.querySelector(btn.getAttribute('data-toggle')));
        });
    });
    document.querySelectorAll('.btn-pikulin-modal-close:not(.pikulin-modal-inited)').forEach((btn) => {
        btn.classList.add('pikulin-modal-inited');
        btn.addEventListener('click',pikulinModalClose);
    });
}
let observerForPikulinModalBtns = new MutationObserver(initPikulinModalBtns);
observerForPikulinModalBtns.observe(document.body, {
    childList: true,
    subtree: true,
});

function bodyOnClickPikulinModelClose(e) {
    if(
        document.body.classList.contains('pikulin-modal-show') &&
        e.target.closest('.pikulin-modal-content') === null &&
        closest(e.target,'pikulin-modal-content') === false &&
        e.target.closest('.btn-pikulin-modal') === null &&
        e.target.closest('.btn-pikulin-modal-close') === null &&
        e.target.closest('.pikulin-modal-no-close') === null
    ) {
        let r = document.querySelector('.pikulin-modal.show .pikulin-modal-content').getBoundingClientRect();
        if(
            e.clientX<r.x ||
            e.clientX>r.x+r.width ||
            e.clientY<r.y ||
            e.clientY>r.y+r.height
        ) {
            e.preventDefault();
            pikulinModalClose();
        }
    }
}
function closest(el,className) {
    if(el.classList.contains(className)) {
        return true
    } else if(el.parentElement===null) {
        return el.tagName!=='HTML';
    } else if(el.parentElement.classList.contains(className)) {
        return true;
    } else {
        return closest(el.parentElement,className);
    }
}

function pikulinModalShow(modal) {
    const openedModal = document.querySelector('.pikulin-modal.show');
    if(openedModal === null) {
        modal.classList.add('show');
        document.body.style.maxWidth = document.body.offsetWidth + 'px';
        document.body.classList.add('pikulin-modal-show');
        if(!modal.classList.contains('pikulin-modal-no-close')) {
            setTimeout(() => {
                document.body.addEventListener('mousedown', bodyOnClickPikulinModelClose);
            }, 300);
        }
    } else {
        openedModal.classList.remove('show');
        modal.classList.add('show');
    }
    pikulinTrigger(modal,'showed');
}

function pikulinModalClose() {
    const modal = document.querySelector('.pikulin-modal.show');
    if(modal!==null) {
        modal.classList.remove('show');
        pikulinTrigger(modal,'closed');
    }
    document.body.classList.remove('pikulin-modal-show');
    document.body.removeEventListener('mousedown',bodyOnClickPikulinModelClose);
}
//EXIT PIKULIN MODAL

//PIKULIN TRIGGER
function pikulinTrigger(element,event) {
    if ("createEvent" in document) {
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent(event, false, true);
        element.dispatchEvent(evt);
    }
    else
        element.fireEvent("on"+event);
}

function isTouchDevice() {
    return ('ontouchstart' in window);
}

//SET CORRECT VH
function set_correct_vh() {
    document.documentElement.style.setProperty('--vh', (window.innerHeight * 0.01)+'px');
}
window.addEventListener('resize', set_correct_vh);
set_correct_vh();